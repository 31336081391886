body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* index.css */
.selected-items {
  display: flex;
  flex-direction: column;
}

/* index.css */

.item-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.item {
  margin: 10px;
  width: 100%; /* Make the item take up the full width of its container */
}

.item img {
  max-width: 100%; /* Make the image responsive */
  height: auto; /* Maintain the image's aspect ratio */
}
